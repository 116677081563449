import { GetterTree } from 'vuex';
import { GeolocationState, RootState } from '../types';

export const getters: GetterTree<GeolocationState, RootState> = {
	getGeolocationResults(state): GeolocationResult[] | null {
		return state.geolocationResults;
	},

	getGeolocationPayload(state): GeolocationPayload {
		return state.geolocationPayload;
	},

	getCountry(state): string | string[] {
		return state.country;
	},

	getGeocoder(state): any {
		return state.geocoder;
	},

	getGeolocationPermission(state): string {
		return state.geolocationPermission;
	},

	getShowDistance(state): boolean {
		return state.showDistance;
	}
};