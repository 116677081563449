import Vue from 'vue';
import { MutationTree } from 'vuex';
import { GeolocationState } from '../types';

export const mutations: MutationTree<GeolocationState> = {
	SET_GEOLOCATION_RESULTS(state, results: GeolocationResult[]): void {
		Vue.set(state, 'geolocationResults', results);
	},

	SET_GEOLOCATION_PAYLOAD(state, geolocationPayload: GeolocationPayload): void {
		Vue.set(state, 'geolocationPayload', geolocationPayload);
	},

	SET_COUNTRY(state, country: string | string[]): void {
		Vue.set(state, 'country', country);
	},

	SET_RADIUS(state, radius: number): void {
		Vue.set(state.geolocationPayload, 'radius', radius);
	},

	SET_UNIT(state, unit: string): void {
		Vue.set(state.geolocationPayload, 'unit', unit);
	},

	SET_GROUP(state, group: string): void {
		Vue.set(state.geolocationPayload, 'group', group);
	},

	SET_GEOLOCATION_PERMISSION(state, permission: GeolocationPermissionState): void {
		Vue.set(state, 'geolocationPermission', permission);
	},

	/**
	 * Set the show distance flag depending on if the search types include area types
	 *
	 * @param {GeolocationState} state
	 * @param {string[]} searchTypes
	 * @return {void}
	 */
	SET_SHOW_DISTANCE(state, searchTypes: string[]): void {
		const areaSearchTypes: string[] = [
			'route',
			'political',
			'country',
			'administrative_area_level_1',
			'administrative_area_level_2',
			'administrative_area_level_3',
			'colloquial_area',
			'locality',
			'sublocality',
			'neighborhood',
			'postal_code'
		];

		Vue.set(state, 'showDistance', !searchTypes.some((type: string) => areaSearchTypes.includes(type)));
	}
};
